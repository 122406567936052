.e-grid {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    list-style: none;
    //margin: 0 ( $spacer * -1) $spacer;
    padding: 0;
    // Modifier classes.
    &.e-grid-bordered {
        margin-left: 0;
        margin-right: 0;
        border-top: $border-width solid $border-color;
        border-left: $border-width solid $border-color;
    }
}

.e-grid-item {
    position: relative;
    z-index: 1;
    margin: 0;
    padding: 0;
    .e-grid-bordered & {
        border-bottom: $border-width solid $border-color;
        border-right: $border-width solid $border-color;
    }
    // Grid item sizes.
    @include media-breakpoint-only(xs) {
        flex: 0 0 ( 100 / 2 * 1%);
        .e-grid-sm & {
            flex: 0 0 ( 100 / 2 * 1%);
        }
    }
    @include media-breakpoint-only(sm) {
        flex: 0 0 ( 100 / 3 * 1%);
        .e-grid-sm & {
            flex: 0 0 ( 100 / 3 * 1%);
        }
    }
    @include media-breakpoint-only(md) {
        flex: 0 0 ( 100 / 3 * 1%);
        .e-grid-sm & {
            flex: 0 0 ( 100 / 4 * 1%);
        }
    }
    @include media-breakpoint-only(lg) {
        flex: 0 0 ( 100 / 4 * 1%);
        .e-grid-sm & {
            flex: 0 0 ( 100 / 5 * 1%);
        }
    }
    @include media-breakpoint-only(xl) {
        flex: 0 0 ( 100 / 5 * 1%);
        .e-grid-sm & {
            flex: 0 0 ( 100 / 5 * 1%);
        }
    }
    // Limit hover state to larger/non-touch devices.
    //TODO: Lav media query til target touch device
    @include media-breakpoint-up(lg) {
        &::before {
            content: "";
            display: none;
            position: absolute;
            top: $spacer / -2;
            bottom: $spacer / -2;
            left: $spacer / -2;
            right: $spacer / -2;
            z-index: 2;
            background-color: $content-bg;
            box-shadow: $shadow-md;
        }
        &:hover {
            z-index: 2;
            &::before {
                display: block;
            }
            .is-hidden-on-hover {
                display: none;
            }
            .is-visible-on-hover {
                display: block;
            }
        }
    }
    .is-hidden-on-hover {
        display: block;
    }
    .is-visible-on-hover {
        display: none;
    }
}

.e-products {
    .e-product-container {
        font-size: $small-font-size;
        position: relative;
        z-index: 3;
        padding: $spacer;
    }
    .e-product-image-container {
        position: relative;
        margin-bottom: $spacer;
        //Image ratios.
        @include media-breakpoint-up(xs) {
            padding-top: percentage( 3 / 4);
        }
        @include media-breakpoint-up(sm) {
            padding-top: percentage( 3 / 4);
        }
        @include media-breakpoint-up(md) {
            padding-top: percentage( 3 / 4);
        }
        @include media-breakpoint-up(lg) {
            padding-top: percentage( 3 / 4);
        }
        @include media-breakpoint-up(xl) {
            padding-top: percentage( 3 / 4);
        }
    }
    .e-product-image-wrapper {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }
    .e-product-image {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 0);
    }
    .e-product-text-container {
        position: relative;
        p {
            line-height: $line-height * 0.875;
            margin-bottom: $spacer / 4;
        }
        a {
            color: inherit;
            text-decoration: none;
        }
    }
    .e-product-name {
        color: $headings-color;
        font-weight: $font-weight-bold;
        a:hover {
            text-decoration: underline;
        }
    }
    .e-product-text {}
    .e-product-price {
        font-size: 1em;
    }
}
