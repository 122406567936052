.e-breadcrumb {
    @include e-canvas-padding;
    @extend .hidden-md-down;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1;
    &.e-breadcrumb-light-shadow {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) 0%, transparent 95%);
    }
}

.breadcrumb {
    font-size: 0.75em;
    margin: 0;
    padding: ( $breadcrumb-padding-vertical * 2 ) 0;
    > li {
        color: $breadcrumb-color;
        padding: 0;
        margin: 0;
        float: left;
        + li::before {
            padding: 0;
            color: $breadcrumb-divider-color;
            content: "#{$breadcrumb-divider}";
        }
        a {
            padding: ( $breadcrumb-padding-vertical / 2) $breadcrumb-padding-horizontal;
            color: inherit;
            text-decoration: underline;
        }
    }
    > .active {
        color: $breadcrumb-active-color;
        a {
            text-decoration: none;
        }
    }
    .e-breadcrumb-light &,
    .e-breadcrumb-light-shadow & {
        > li,
        > li + li::before {
            color: $section-light-color;
        }
        > .active {
            color: $section-light-headings-color;
        }
    }
    .e-breadcrumb-dark & {
        > li,
        > li + li::before {
            color: $section-dark-color;
        }
        > .active {
            color: $section-dark-headings-color;
        }
    }
}
