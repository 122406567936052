// Form groups
//
// Designed to help with the organization and spacing of vertical forms. For
// horizontal forms, use the predefined grid classes.
.form-group {
    small {
        display: block;
        font-weight: $font-weight-normal;
    }
    .form-control-success {
        //background-image: url($form-icon-success);
    }
    .e-form-error-placeholder {
        display: none;
    }
    &.has-focus {
        //color: $input-border-focus;
        label {
            color: inherit;
        }
    }
    &.has-error {
        label.has-error {
            color: $brand-danger;
        }
        .form-control-danger {
            border-color: $brand-danger;
            background-image: url($form-icon-warning);
        }
    }
}

//
// Textual form controls
//
.form-control {
    background-clip: padding-box;
}

// Remove clear botton on input fields in IE10+
input[type=text]::-ms-clear {
    display: none;
}

// prevent iphone from zooming in when filling out a form, like login or application
@media only screen and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 2) {
    button[type="submit"],
    select,
    textarea,
    input,
    input:focus,
    input:hover {
        font-size: 16px !important;
    }
}

//
// Labels
//
// For use with horizontal and inline forms, when you need the label text to
// align with the form controls.
.form-control-label {
    display: block;
    padding: 0 0 ($input-padding-y / 2) 0;
    .e-form-dw-labels-bold & {
        font-weight: $font-weight-bold;
    }
    .e-form-dw-labels-hide & {
        display: none;
    }
}

legend,
.form-group-legend {
    margin-bottom: $spacer / 4;
    font-size: 1em;
    font-weight: $font-weight-bold;
}

// Form control feedback states
//
// Apply contextual and semantic states to individual form controls.
.form-control-success,
.form-control-warning,
.form-control-danger {
    background-position: top $input-padding-y right $input-padding-x;
}

// Checkboxes and radios
//
// Indent the labels to position radios/checkboxes as hanging controls.
.radio,
.checkbox {
    margin-bottom: 0;
    label {
        //padding-left: strip-unit($spacer) * 1rem;
        font-weight: inherit;
        // When there's no labels, don't position the input.
        input:only-child {
            //Yes. Do that anyway.
            position: absolute;
        }
    }
}

.radio input[type="radio"],
.radio-inline input[type="radio"],
.checkbox input[type="checkbox"],
.checkbox-inline input[type="checkbox"] {
    margin-top: strip-unit($spacer) / 5 * 1rem;
    //margin-left: strip-unit($spacer) * -1rem;
}

.radio + .radio,
.checkbox + .checkbox {
    // Move up sibling radios or checkboxes for tighter spacing
    margin-top: $spacer / 4;
}

// Radios and checkboxes on same line
.radio-inline,
.checkbox-inline {
    padding-left: $spacer;
}

.radio-inline + .radio-inline,
.checkbox-inline + .checkbox-inline {
    margin-left: $spacer / 2;
}
