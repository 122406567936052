// Button variants
//
// Easily pump out default styles, as well as :hover, :focus, :active,
// and disabled options for all buttons
@mixin button-variant($color, $background, $border, $background-hover: darken($background, 10%)) {
    $active-background: $background-hover;
    $active-border: $border;
    color: $color;
    background-color: $background;
    border-color: $border;
    @include box-shadow($btn-box-shadow);
    @include hover {
        color: $color;
        background-color: $active-background;
        border-color: $active-border;
    }
    &:focus,
    &.focus {
        color: $color;
        background-color: $active-background;
        border-color: $active-border;
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        color: $color;
        background-color: $active-background;
        border-color: $active-border;
        // Remove the gradient for the pressed/active state
        background-image: none;
        @include box-shadow($btn-active-box-shadow);
        &:hover,
        &:focus,
        &.focus {
            color: $color;
            background-color: $background;
            border-color: $border;
        }
    }
    &.disabled,
    &:disabled {
        &:focus,
        &.focus {
            background-color: $background;
            border-color: $border;
        }
        @include hover {
            background-color: $background;
            border-color: $border;
        }
    }
}
