.e-form-dw {
    .form-control {
        border-radius: 2em;
        min-height: 3em;
        padding-left: 1.5em;
    }
    textarea.form-control {
        height: 8em;
        padding-top: 1.5em;
    }
}

.form-group.has-error label.has-error {
    position: absolute;
    margin-top: -2.3em;
    right: 3em;
}
