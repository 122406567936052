.p-tile-height-sm {
    .p-txt-container {
        img.trust-icon {
            height: 4em;
            position: absolute;
            right: 2em;
            left: auto;
            bottom: auto;
            top: 2em;
            opacity: 0.7;
        }
    }
    @include media-breakpoint-down(xs) {
        height: 28vh;
        min-height: 12em;
    }
}

.p-tile .p-txt-container {
    padding-bottom: 3em;
}


/*
.p-tile-bg-white {
    &::after {
        content: "";
        height: 0.3em;
        background: repeating-linear-gradient( 45deg, #fff, #fff 10px, $gray-lightest 10px, $gray-lightest 20px);
        bottom: 0px;
        position: absolute;
        width: 100%;
    }
}

.p-tile-bg-brand-variation-one {
    &::after {
        content: "";
        height: 0.3em;
        background: repeating-linear-gradient( 45deg, $brand-secondary, $brand-secondary 10px, $brand-variation-one 10px, $brand-variation-one 20px);
        bottom: 0px;
        position: absolute;
        width: 100%;
    }
}

.p-tile-bg-brand-variation-two {
    &::after {
        content: "";
        height: 0.3em;
        background: repeating-linear-gradient( 45deg, $brand-primary, $brand-primary 10px, $brand-variation-two 10px, $brand-variation-two 20px);
        bottom: 0px;
        position: absolute;
        width: 100%;
    }
}
*/
