/*!
 * Bootstrap v4.0.0-alpha.2 (http://getbootstrap.com)
 * Copyright 2011-2015 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

// Core functions, variables and mixins
@import "espresso/e-functions";

@import "espresso/variables";
@import "bootstrap/variables";
@import "custom/variables";

@import "bootstrap/mixins";
@import "espresso/mixins";
//@import "custom/mixins";

// Reset and dependencies
@import "bootstrap/normalize";
//@import "bootstrap/print";

// Core CSS
@import "bootstrap/reboot";
@import "bootstrap/type";
@import "espresso/type";
@import "bootstrap/images";
//@import "bootstrap/code";
@import "bootstrap/grid";
@import "espresso/grid";
@import "bootstrap/tables";
@import "espresso/tables";
@import "bootstrap/forms";
@import "espresso/forms";
@import "bootstrap/buttons";
@import "espresso/buttons";

// Components
@import "bootstrap/animation";
@import "espresso/animation";
@import "bootstrap/dropdown";
@import "espresso/dropdown";
//@import "bootstrap/button-group";
//@import "bootstrap/input-group";
//@import "espresso/input-group";
//@import "bootstrap/custom-forms";
@import "bootstrap/nav";
@import "espresso/nav";
//@import "bootstrap/navbar";
@import "bootstrap/card";
@import "espresso/card";
@import "bootstrap/breadcrumb";
@import "espresso/breadcrumb";
@import "bootstrap/pagination";
@import "espresso/pagination";
@import "bootstrap/pager";
//@import "bootstrap/labels";
//@import "bootstrap/jumbotron";
@import "bootstrap/alert";
//@import "bootstrap/progress";
@import "bootstrap/media";
@import "bootstrap/list-group";
@import "bootstrap/responsive-embed";
@import "espresso/responsive-embed";
@import "bootstrap/close";
@import "espresso/close";

// Components w/ JavaScript
@import "bootstrap/modal";
@import "espresso/modal";
//@import "bootstrap/tooltip";
//@import "bootstrap/popover";
@import "bootstrap/carousel";
@import "espresso/carousel";
@import "espresso/collapse";

// Utility classes
@import "bootstrap/utilities";
//@import "bootstrap/utilities-background";
@import "bootstrap/utilities-spacing";
@import "bootstrap/utilities-responsive";

// Vendor plugins
//@import "vendor/custom";
@import "espresso/material-icons";

// Espresso CMS
@import "espresso/e-canvas";
@import "espresso/e-header";
@import "espresso/e-content";
@import "espresso/e-content-main";
//@import "espresso/e-content-sticky-footer";
@import "espresso/e-footer";
@import "espresso/e-nav-global";
@import "espresso/e-nav-cta";
@import "espresso/e-nav-search";
@import "espresso/e-nav-utilities-primary";
@import "espresso/e-nav-utilities-secondary";
@import "espresso/e-nav-local-horizontal";
//@import "espresso/e-nav-footer";
@import "espresso/e-paragraph";
@import "espresso/e-paragraph-imagetext";
@import "espresso/e-paragraph-icontext";
@import "espresso/e-paragraph-banner";
@import "espresso/e-paragraph-mosaic";
@import "espresso/e-paragraph-section";
@import "espresso/e-paragraph-video";
@import "espresso/e-paragraph-divider";
@import "espresso/e-paragraph-carousel";
@import "espresso/e-paragraph-contact";
@import "espresso/e-paragraph-card";
@import "espresso/e-paragraph-tile";

@import "espresso/e-search";
@import "espresso/e-news";
@import "espresso/e-scroll-reveal";
@import "espresso/e-back-to-top";
@import "espresso/e-utilities";
@import "espresso/e-cookie-message";


// Espresso eCommerce
@import "espresso/ecom-cart";
@import "espresso/ecom-checkout";
@import "espresso/ecom-products-grid";
@import "espresso/ecom-product-details";

// Custom styling
@import "custom/custom";
