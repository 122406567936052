@include media-breakpoint-down($nav-stacking-breakpoint) {
    .e-nav-global .nav-link.is-active {
        color: #fff;
    }
}

@include media-breakpoint-up(breakpoint-next($nav-stacking-breakpoint)) {
    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-global {
        > .nav {
            >.nav-item {
                a {
                    position: relative;
                    -webkit-transition: color .3s ease;
                    transition: color .3s ease;
                    &:hover {
                        &::before {
                            visibility: visible;
                            -webkit-transform: scaleX(1);
                            transform: scaleX(1);
                        }
                    }
                }
                >.nav-link {
                    margin: 1.6em 1.4em;
                    padding: 0;
                    &::before {
                        content: "";
                        position: absolute;
                        height: 1px;
                        top: auto;
                        bottom: 0;
                        background-color: inherit;
                        visibility: hidden;
                        -webkit-transform: scaleX(0);
                        transform: scaleX(0);
                        -webkit-transition: all .3s ease-in-out;
                        transition: all .3s ease-in-out;
                    }
                    text-transform: none;
                    &.is-inpath,
                    &.is-active {
                        color: $header-color;
                        font-weight: $font-weight-bold;
                        &::before {
                            visibility: visible;
                            -webkit-transform: scaleX(1);
                            transform: scaleX(1);
                        }
                    }
                    &:hover {
                        &::before {
                            background: rgba($header-color, 1);
                        }
                    }
                    &.is-inpath,
                    &.is-active {
                        &::before {
                            background: rgba($header-color, 1);
                        }
                    }
                }
            }
        }
    }
}
