.e-cart {
    position: relative;
    @include media-breakpoint-up(md) {
        margin-top: $spacer * 2;
    }
    .e-loading-overlay {
        position: fixed;
        max-height: 100vh;
    }
}

.e-cart-summery {
    @include media-breakpoint-down(md) {
        hr {
            margin: ($spacer / 2) 0;
        }
        .e-cart-actions {
            margin-top: ($spacer / 2);
        }
    }
    @include media-breakpoint-up(md) {
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        margin-top: $border-width * -1;
        margin-bottom: $spacer;
        padding: 0 $spacer;
    }
}

.e-cart-delivery-info {
    @include media-breakpoint-up(md) {
        padding: 0 $spacer;
    }
}

.e-cart-footer {
    background: $content-bg;
    position: sticky;
    bottom: 0;
}

.e-cart-msg {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    z-index: $zindex-navbar-fixed - 1;
    display: none;
    overflow: hidden;
    opacity: 0;
    background: $content-bg;
    box-shadow: $shadow-lg;
    transition: opacity $fade-speed $fade-easing;
    &.is-active {
        opacity: 1;
        display: block;
    }
}

.e-cart-msg-info {
    @include e-txt-dark;
    @include e-bg-light;
    height: 100%;
}

.e-cart-mini {
    a {
        position: relative;
    }
    i {
        position: relative;
        z-index: 1;
    }
}

.e-cart-count {
    background: $brand-primary;
    height: 1.75em;
    width: 1.75em;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    position: absolute;
    z-index: 2;
    top: 50%;
    right: 50%;
    transform: translate(110%, -110%);
    transition: opacity $fade-speed $fade-easing;
    &:empty {
        opacity: 0;
    }
}
