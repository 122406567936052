// Wrapper for the slide container and indicators
.carousel {
    height: 50vw;
}

.carousel-inner {
    height: 100%;
    > .carousel-item {
        height: 100%;
        width: 100%;
        transition: left $carousel-speed $carousel-easing, transform $carousel-speed $carousel-easing;
    }
}

//
// Left/right controls for nav
//
.carousel-control {
    top: 50%;
    bottom: auto;
    transform: translateY(-50%);
    border-radius: 50%;
    line-height: 1;
    padding: 0;
    &.left,
    &.right {
        background: $carousel-control-bg;
        @include media-breakpoint-down(md) {
            color: $carousel-control-hover-color;
            background: $carousel-control-hover-bg;
            opacity: 1;
        }
    }
    &.left {
        left: 1em;
    }
    &.right {
        right: 1em;
    }
    &:hover,
    &:focus {
        color: $carousel-control-hover-color;
        background: $carousel-control-hover-bg;
    }
    .carousel:hover & {
        opacity: 1;
    }
}

// Optional indicator pips
//
// Add an unordered list with the following class and add a list item for each
// slide your carousel holds.
.carousel-indicators {
    bottom: $spacer / 2;
    left: 0;
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    li {
        font-size: 0.25em;
        border-radius: 50%;
        background: $carousel-indicator-bg;
        border-width: 1px;
        background-clip: content-box;
        position: relative;
        width: 2.5em;
        height: 2.5em;
        margin: 0.5em;
        padding: 0;
        transition: border-color $carousel-speed, background-color $carousel-speed;
    }
    .active {
        width: 2.5em;
        height: 2.5em;
        margin: 0.5em;
        border-color: $carousel-indicator-active-border-color;
    }
}

// Optional captions
//
// Hidden by default for smaller viewports.
.carousel-caption {
    @include e-canvas-padding;
    position: absolute;
    right: 0;
    bottom: $spacer;
    left: 0;
    padding-top: $spacer;
    padding-bottom: $spacer;
    text-align: left;
    pointer-events: none;
    .active & {
        opacity: 1;
        transform: translateY(0);
        transition: opacity $carousel-speed $carousel-easing, transform $carousel-speed * 2 $carousel-easing;
    }
    .active.left & {
        //På vej ud mod ventre
        opacity: 0.5;
        transition: opacity $carousel-speed * 1.5 $carousel-easing;
    }
    .active.right & {
        //På vej ud mod højre
        opacity: 0.5;
        transition: opacity $carousel-speed * 1 $carousel-easing;
    }
    .next.left & {
        // På vej ind fra højre
        transform: translateY($spacer*2);
        opacity: 0;
    }
    .prev.right & {
        // På vej ind fra ventre
        opacity: 0;
        transform: translateY($spacer*2);
    }
    h1,
    h2,
    h3,
    h4,
    p {
        color: rgba($carousel-caption-color, 1);
        line-height: $headings-line-height;
        margin-bottom: 0.5rem;
    }
    h4 {
        color: rgba($carousel-caption-color, 0.85);
        margin-bottom: 0.25rem;
    }
    p {
        //margin-bottom: 0.25rem;
    }
    .btn {
        margin-top: 1rem;
        pointer-events: all;
    }
}
