.p-card {
    display: flex;
    margin-bottom: $spacer;
    .card {
        @include e-txt-dark;
        background-color: #fff;
        width: 100%;
    }
    &.p-card-top-bottom {
        .card {
            //display: flex;
            //flex-direction: column;
        }
    }
    .p-txt-container {
        font-size: $small-font-size;
        width: 100%;
        //flex-grow: 1;
        .card-block {}
        h1,
        h2,
        h3,
        h4,
        p,
        ul,
        table {
            margin-bottom: 0.5rem;
        }
        :last-child {
            margin-bottom: 0;
        }
    }
    .p-img-container {
        width: 100%;
        .card-block {
            padding-bottom: 0;
        }
        .col-pos-center {
            margin-left: auto;
            margin-right: auto;
        }
        .col-pos-right {
            margin-left: auto;
        }
        .p-img[src$="svg"] {
            width: 100%;
        }
    }
}

.p-card-img-bleed {
    .p-img-container {
        .card-block {
            padding: 0;
        }
        .row {
            margin: 0;
        }
        .col-xs-12 {
            padding: 0;
        }
        .p-img {
            border-radius: $card-border-radius $card-border-radius 0 0;
        }
    }
}

.p-card-link {
    .card {
        cursor: pointer;
    }
}

// Text size
.p-card-txt-small {
    .p-txt-container {
        font-size: $small-font-size;
    }
}

.p-card-txt-medium {
    .p-txt-container {
        font-size: 1em;
    }
}

.p-card-txt-large {
    .p-txt-container {
        font-size: $lead-font-size;
    }
}

// Shadows and hover state
.p-card {
    &:not(.p-card-hover-disabled):hover {
        .card {
            box-shadow: $shadow-lg;
        }
    }
}

.p-card-shadow-none {
    .card {
        box-shadow: none;
    }
    &:not(.p-card-hover-disabled):hover {
        .card {
            box-shadow: none;
        }
    }
}

.p-card-shadow-small {
    .card {
        box-shadow: $shadow-sm;
    }
    &:not(.p-card-hover-disabled):hover {
        .card {
            box-shadow: $shadow-md;
        }
    }
}

.p-card-shadow-medium {
    .card {
        box-shadow: $shadow-md;
    }
    &:not(.p-card-hover-disabled):hover {
        .card {
            box-shadow: $shadow-lg;
        }
    }
}

.p-card-shadow-large {
    .card {
        box-shadow: $shadow-lg;
    }
    &:not(.p-card-hover-disabled):hover {
        .card {
            box-shadow: $shadow-xl;
        }
    }
}

// Padding
.p-card-padding-small {
    .card-block {
        padding: 5%;
    }
}

.p-card-padding-medium {
    .card-block {
        padding: 10%;
    }
}

.p-card-padding-large {
    .card-block {
        padding: 15%;
    }
}

// Colors
.p-card-bg-white {
    .card {
        @include e-txt-dark;
        background-color: #fff;
    }
}

.p-card-bg-black {
    .card {
        @include e-txt-light;
        background-color: #000;
    }
}

.p-card-bg-light {
    .card {
        @include e-txt-dark;
        @include e-bg-light;
    }
}

.p-card-bg-dark {
    .card {
        @include e-txt-light;
        @include e-bg-dark;
    }
}

.p-card-bg-brand-primary {
    .card {
        @include e-section-primary;
    }
}

.p-card-bg-brand-secondary {
    .card {
        @include e-section-secondary;
    }
}

.p-card-bg-brand-variation-one {
    .card {
        @include e-section-variation-one;
    }
}

.p-card-bg-brand-variation-two {
    .card {
        @include e-section-variation-two;
    }
}

// Text
.p-card-txt-dark {
    @include e-txt-dark;
}

.p-card-txt-light {
    @include e-txt-light;
}

.p-card-txt-bg-white {
    .p-txt-container {
        background-color: #fff;
    }
}

.p-card-txt-bg-black {
    .p-txt-container {
        background-color: #000;
    }
}

.p-card-txt-bg-light {
    .p-txt-container {
        background: $section-light-bg;
    }
}

.p-card-txt-bg-dark {
    .p-txt-container {
        background: $section-dark-bg;
    }
}

.p-card-txt-bg-brand-primary {
    .p-txt-container {
        background-color: $section-primary-bg;
    }
}

.p-card-txt-bg-brand-secondary {
    .p-txt-container {
        background-color: $section-secondary-bg;
    }
}

.p-card-txt-bg-brand-variation-one {
    .p-txt-container {
        background-color: $section-variation-one-bg;
    }
}

.p-card-txt-bg-brand-variation-two {
    .p-txt-container {
        background-color: $section-variation-two-bg;
    }
}

// Border width
.p-card-border-thin {
    .card {
        border-width: $border-width;
    }
}

.p-card-border-thick {
    .card {
        border-width: 0.375em;
    }
}

// Border colors
.p-card-border-white {
    border-color: #fff;
}

.p-card-border-black {
    border-color: #000;
}

.p-card-border-light {
    border-color: $section-dark-border;
}

.p-card-border-dark {
    border-color: $section-light-border;
}

.p-card-border-brand-primary {
    border-color: $brand-primary;
}

.p-card-border-brand-secondary {
    border-color: $brand-secondary;
}
