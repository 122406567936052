.p-icontext {
    margin-bottom: $spacer;
    :last-child {
        margin-bottom: 0;
    }
    .row-xs-center {}
    .p-img {
        margin-bottom: $spacer / 2;
        position: relative;
        padding-top: 100%;
        // http://tympanus.net/codrops/2014/08/19/making-svgs-responsive-with-css/
        svg {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;
            max-height: 100%;
        }
    }
}

.p-icontext-white {
    svg {
        [id*="Filled_Icon"] {
            fill: #fff;
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: #fff;
            }
        }
    }
}

.p-icontext-black {
    svg {
        [id*="Filled_Icon"] {
            fill: #000;
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: #000;
            }
        }
    }
}

.p-icontext-light {
    svg {
        [id*="Filled_Icon"] {
            fill: $gray-light;
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: $gray-light;
            }
        }
    }
}

.p-icontext-dark {
    svg {
        [id*="Filled_Icon"] {
            fill: $gray-dark;
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: $gray-dark;
            }
        }
    }
}

.p-icontext-brand-primary {
    svg {
        [id*="Filled_Icon"] {
            fill: $brand-primary;
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: $brand-primary;
            }
        }
    }
}

.p-icontext-brand-secondary {
    svg {
        [id*="Filled_Icon"] {
            fill: $brand-secondary;
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: $brand-secondary;
            }
        }
    }
}

.p-icontext-brand-variation-one {
    svg {
        [id*="Filled_Icon"] {
            fill: $brand-variation-one;
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: $brand-variation-one;
            }
        }
    }
}

.p-icontext-brand-variation-two {
    svg {
        [id*="Filled_Icon"] {
            fill: $brand-variation-two;
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: $brand-variation-two;
            }
        }
    }
}

.p-icontext-auto,
.p-icontext .p-img[style] {
    svg {
        [id*="Filled_Icon"] {
            fill: currentColor;
        }
        [id*="Outline_Icon"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: currentColor;
            }
        }
    }
}
