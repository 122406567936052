.e-footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: -1;
    .e-footer-content {
        padding-top: 4em;
    }
    .e-footer-copyright {
        padding-bottom: 2em;
    }
}

.e-footer-content {
    .form-control {
        border-radius: 2em;
        height: 3em;
        padding-left: 1.5em;
    }
    .form-group {
        margin-bottom: 1em;
    }
    .btn {
        width: 100%;
    }
}
