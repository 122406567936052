.p-tile {
    padding: 0;
    font-size: $small-font-size;
    .p-section-columns > .container-fluid > .row > .col-xs-12 > .row & {
        &.col-xs-4 {
            float: left;
            width: 100 / 3 * 1%;
        }
        &.col-xs-6 {
            float: left;
            width: 50%;
        }
        &.col-xs-12 {
            @include media-breakpoint-up(sm) {
                float: left;
                width: 100%;
            }
        }
    }
    .p-txt-container {
        font-size: $small-font-size;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        z-index: 2;
        min-height: 100%;
        padding: 5%;
        overflow: hidden;
        @include media-breakpoint-down(sm) {
            padding-bottom: 7.5%;
        }
        @include media-breakpoint-up(md) {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            overflow: hidden;
            padding-right: 20%;
        }
        @include media-breakpoint-up(lg) {
            padding-right: 25%;
        }
        @include media-breakpoint-up(xl) {
            padding-right: 40%;
        }
    }
    h2 {
        margin-bottom: 0.5rem;
    }
    &.p-tile-link {
        h2::after {
            content: 'arrow_forward';
            font-family: 'Material Icons';
            font-weight: normal;
            font-style: normal;
            font-size: 1em;
            vertical-align: -10%;
            letter-spacing: normal;
            text-transform: none;
            display: inline-block;
            white-space: nowrap;
            word-wrap: normal;
            direction: ltr;
            font-feature-settings: 'liga';
            font-smoothing: antialiased;
            width: 0;
            transform: translateX(0.25em);
            transition: transform $slide-speed / 2 $slide-easing;
        }
        &:hover {
            h2::after {
                transform: translateX(0.75em);
            }
            .p-img {
                transform: scale(1.125);
            }
        }
    }
    h4 {
        margin-bottom: 0.5rem;
        opacity: 1;
    }
    a {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 3;
    }
    :last-child {
        margin-bottom: 0;
    }
}

//Image tile specific styling
.p-tile-img {
    .p-txt-container {
        h2 {
            color: $section-dark-headings-color;
        }
        h4 {
            color: $section-dark-color;
        }
    }
    .p-img-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
        overflow: hidden;
    }
    .p-img {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        transition: transform $fade-speed;
        backface-visibility: hidden;
        &:not(.p-tile-gradient-disabled)::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            background: linear-gradient(22deg, rgba(0, 0, 0, 0.95) 0%, rgba(0, 0, 0, 0) 60%);
        }
    }
    .p-img-left-top {
        background-position: 0 0;
    }
    .p-img-left-middle {
        background-position: 0 50%;
    }
    .p-img-left-bottom {
        background-position: 0 100%;
    }
    .p-img-center-top {
        background-position: 50% 0;
    }
    .p-img-center-middle {
        background-position: 50% 50%;
    }
    .p-img-center-bottom {
        background-position: 50% 100%;
    }
    .p-img-right-top {
        background-position: 100% 0;
    }
    .p-img-right-middle {
        background-position: 100% 50%;
    }
    .p-img-right-bottom {
        background-position: 100% 100%;
    }
}

// Heights
.p-tile-height-auto {
    .p-txt-container {
        position: static;
    }
}

.p-tile-height-xs {
    height: 25vh;
    min-height: 1em * 25 / 2;
}

.p-tile-height-sm {
    height: 33.33333vh;
    min-height: 1em * 33.33333 / 2;
}

.p-tile-height-md {
    height: 50vh;
    min-height: 1em * 50 / 2;
}

.p-tile-height-lg {
    height: 66.66666vh;
    min-height: 1em * 66.66666 / 2;
}

.p-tile-height-xl {
    height: 75vh;
    min-height: 1em * 75 / 2;
}

.p-tile-height-fullscreen {
    height: 100vh;
    @include media-breakpoint-up(sm) {
        min-height: 1em * 100 / 2;
    }
}

//Colors
.p-tile-bg-white {
    @include e-txt-dark;
    background-color: #fff;
}

.p-tile-bg-black {
    @include e-txt-light;
    background-color: #000;
}

.p-tile-bg-light {
    @include e-txt-dark;
    @include e-bg-light;
}

.p-tile-bg-dark {
    @include e-txt-light;
    @include e-bg-dark;
}

.p-tile-bg-brand-primary {
    @include e-section-primary;
}

.p-tile-bg-brand-secondary {
    @include e-section-secondary;
}

.p-tile-bg-brand-variation-one {
    @include e-section-variation-one;
}

.p-tile-bg-brand-variation-two {
    @include e-section-variation-two;
}

.p-tile-txt-dark {
    @include e-txt-dark;
}

.p-tile-txt-light {
    @include e-txt-light;
}

@include media-breakpoint-down(sm) {
    .p-tile-order-first {
        order: -1;
    }
    .p-tile-order-last {
        order: 1;
    }
}
