.e-canvas {
    position: relative;
    max-width: $canvas-max-width;
    margin-right: auto;
    margin-left: auto;
    z-index: 1;
    @include media-breakpoint-down($nav-stacking-breakpoint) {
        border-top: ( $header-logo-height-small + ( $header-logo-spacing-small * 1.9375 ) ) solid $header-bg;
    }
    @include media-breakpoint-up(breakpoint-next($nav-stacking-breakpoint)) {
        border-top: ( $header-logo-height-large + ( $header-logo-spacing-large * 1.9375 ) ) solid $header-bg;
    }
}

.e-canvas-fullscreen {
    max-width: 100%;
    border-top: 0;
    &::before {
        content: "";
        position: absolute;
        z-index: 1;
        top: 0;
        right: 0;
        left: 0;
        height: 5em;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.25) -2em, rgba(0, 0, 0, 0) 5em);
    }
}
