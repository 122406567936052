.btn {
    border-radius: 2.125em;
    box-shadow: none;
    text-transform: inherit;
    font-weight: $font-weight-bold;
}

.btn.btn-primary {
    &:hover {
        background: $brand-primary;
    }
}

.btn.btn-secondary {
	color: $brand-secondary;
    border: 3px solid $brand-secondary;
    &:hover {
    	color: $brand-secondary;
        border: 3px solid $brand-secondary;
    }
}
