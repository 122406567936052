html {
    @include media-breakpoint-up(xs) {
        font-size: $font-size-root * 0.875;
    }
    @include media-breakpoint-up(sm) {
        font-size: $font-size-root * 0.9375;
    }
    @include media-breakpoint-up(md) {
        font-size: $font-size-root * 1;
    }
    @include media-breakpoint-up(lg) {
        font-size: $font-size-root * 1.0625;
    }
    @include media-breakpoint-up(xl) {
        font-size: $font-size-root * 1.125;
    }
}

body {
    -webkit-font-smoothing: antialiased;
    font-weight: $font-weight-base;
}

//
// Headings
//
h1,
h2,
h3,
h4,
.h1,
.h2,
.h3,
.h4 {
    margin-top: $headings-margin-top;
    &:first-child {
        margin-top: 0;
    }
    a {
        color: inherit;
        &:hover {
            color: inherit;
            text-decoration: underline;
        }
    }
}

h1,
.h1 {
    margin-left: -0.05em;
    font-family: $font-family-h1;
    font-size: $font-size-h1;
    font-weight: $font-weight-h1;
    letter-spacing: -0.025em;
    &.small {
        font-size: $font-size-h1 * 0.875;
        font-weight: $font-weight-h1;
    }
    @include media-breakpoint-down(sm) {
        font-size: $font-size-h1 * 0.875;
        &.small {
            font-size: strip-unit($font-size-h1) * $small-font-size * 0.875;
        }
    }
    @include media-breakpoint-down(xs) {
        font-size: $font-size-h1 * 0.75;
        &.small {
            font-size: strip-unit($font-size-h1) * $small-font-size * 0.75;
        }
    }
}

h2,
.h2 {
    margin-left: -0.05em;
    font-family: $font-family-h2;
    font-size: $font-size-h2;
    font-weight: $font-weight-h2;
    &.small {
        font-size: $font-size-h2 * 0.875;
        font-weight: $font-weight-h2;
    }
    @include media-breakpoint-down(sm) {
        font-size: $font-size-h2 * 0.9375;
        &.small {
            font-size: strip-unit($font-size-h2) * $small-font-size * 0.9375;
        }
    }
    @include media-breakpoint-down(xs) {
        font-size: $font-size-h2 * 0.875;
        &.small {
            font-size: strip-unit($font-size-h2) * $small-font-size * 0.875;
        }
    }
}

h3,
.h3 {
    font-family: $font-family-h3;
    font-size: $font-size-h3;
    font-weight: $font-weight-h3;
    line-height: $headings-line-height * 1.125;
    color: $body-color;
    margin-bottom: $headings-margin-bottom / 2;
    &.small {
        font-size: $font-size-h3 * 0.875;
        font-weight: $font-weight-h3;
    }
    @include media-breakpoint-down(sm) {
        font-size: $font-size-h3 * 1.0625;
        &.small {
            font-size: strip-unit($font-size-h3) * $small-font-size * 1.0625;
        }
    }
    @include media-breakpoint-down(xs) {
        font-size: $font-size-h3 * 1.125;
        &.small {
            font-size: strip-unit($font-size-h3) * $small-font-size * 1.125;
        }
    }
}

h4,
.h4 {
    font-family: $font-family-h4;
    font-size: $font-size-h4;
    font-weight: $font-weight-h4;
    color: $body-color;
    margin-bottom: $headings-margin-bottom / 2;
    text-transform: uppercase;
    &.small {
        font-size: $font-size-h4 * 0.875;
        font-weight: $font-weight-h4;
    }
    + h1,
    + h2,
    + h3,
    + h4 {
        margin-top: 0;
    }
}

.lead {
    font-family: $lead-font-family;
    font-size: $lead-font-size;
    font-weight: $lead-font-weight;
    &.small {
        font-size: $lead-font-size * 0.875;
        font-weight: $lead-font-weight;
    }
    @include media-breakpoint-down(xs) {
        font-size: $lead-font-size * 0.9375;
        &.small {
            font-size: strip-unit($lead-font-size) * $small-font-size * 0.9375;
        }
    }
}

.text-muted {
    opacity: $text-muted !important;
}

.text-light {
    color: $section-dark-color !important;
}

.text-dark {
    color: $section-light-color !important;
}

.text-white {
    color: #fff !important;
}

.text-black {
    color: #000 !important;
}

.text-secondary {
    color: $brand-secondary !important;
}

.text-variation-one {
    color: $brand-variation-one !important;
}

.text-variation-two {
    color: $brand-variation-two !important;
}

.text-link {
    color: $link-color !important;
}

.text-auto {
    color: inherit !important;
}

p {
    margin-bottom: $spacer;
    @include media-breakpoint-down(md) {
        font-size: $font-size-base * 1.0625;
        &.small {
            font-size: strip-unit($font-size-base) * $small-font-size * 1.0625;
        }
    }
    @include media-breakpoint-down(sm) {
        font-size: $font-size-base * 1.125;
        &.small {
            font-size: strip-unit($font-size-base) * $small-font-size * 1.125;
        }
    }
}

//
// Emphasis
//
small,
.small {
    font-size: $small-font-size;
    font-weight: $small-font-weight;
}

//
// Links
//
a[href^="tel:"] {
    @include media-breakpoint-up(md) {
        color: inherit;
        text-decoration: none;
        pointer-events: none;
    }
}

a.arrow-left::before,
a.arrow-right::after,
a.arrow-up::before,
a.arrow-down::after {
    content: "";
    font-family: "Material Icons";
    font-style: normal;
    font-size: 1em;
    vertical-align: -10%;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: "liga";
    font-smoothing: antialiased;
    color: inherit;
    transition: transform $slide-speed / 2 $slide-easing;
    text-decoration: none;
}

a.arrow-right::after,
a.arrow-down::after {
    margin: 0 0 0 0.5em;
}

a.arrow-left::before,
a.arrow-up::before {
    margin: 0 0.5em 0 0;
}

a.arrow-right {
    &::after {
        content: "arrow_forward";
    }
    &:hover::after {
        transform: translateX(0.5em);
    }
}

a.arrow-left {
    &::before {
        content: "arrow_back";
    }
    &:hover::before {
        transform: translateX(-0.5em);
    }
}

a.arrow-up {
    &::before {
        content: "arrow_upward";
    }
    &:hover::before {
        transform: translateY(-0.5em);
    }
}

a.arrow-down {
    &::after {
        content: "arrow_downwards";
    }
    &:hover::after {
        transform: translateY(0.5em);
    }
}

//
// Lists
//
ol,
ul {
    margin-top: $spacer;
    margin-bottom: $spacer * 1.5;
    padding-left: $spacer * 1.5;
    &:first-child {
        margin-top: 0;
    }
    h1 + &,
    h2 + &,
    h3 + &,
    h4 + & {
        margin-top: $spacer;
    }
    li {
        margin-bottom: $spacer * 0.5;
        padding-left: $spacer * 0.5;
    }
}

.list-inline-item,
.list-unstyled > li {
    padding-left: 0;
}

// dw styles
pre.dw-error {
    white-space: normal;
}
