.e-header {
    right: auto;
    left: auto;
    width: 100%;
    box-shadow: none;
    @media (min-width: $canvas-max-width) {
        width: $canvas-max-width * 1;
    }
    padding-right: $canvas-padding-xs * 1.75;
    padding-left: $canvas-padding-xs * 1.75;
    @include media-breakpoint-up(xl) {
        padding-right: $canvas-padding-xl * 1.25;
        padding-left: $canvas-padding-xl * 1.25;
    }
    @media (min-width: $canvas-max-width) {
        padding-right: $canvas-max-width / 8.3;
        padding-left: $canvas-max-width / 8.3;
    }
    @media (max-width: $canvas-max-width) {
        .e-logo {
            margin-top: 0.5em;
        }
    }
    .col-xs-12 {
        padding-left: 0;
        padding-right: 0;
    }
}
