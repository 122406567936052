.e-cookie-message {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    height: auto;
    background: $headings-color;
    color: #fff;
    font-size: $font-size-sm;
    padding: 2.2em;
    z-index: 1000;
    text-align: center;
    width: 100%;
    margin: auto;
    overflow: hidden;
    display: flex;
    .e-section {
        width: 100%;
    }
    .container-fluid {
        padding-left: 0;
        padding-right: 0;
    }
    p {
        margin-bottom: 0;
    }
    .e-cookie-text {
        float: left;
        text-align: left;
        width: 85%;
        flex: 0 0 75%;
        @include media-breakpoint-down(md) {
            width: 100%;
            text-align: center;
        }
    }
    .e-cookie-btn {
        @include media-breakpoint-up(lg) {
            margin: 0 auto;
            float: right;
            top: 7.25%;
            position: relative;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
    a {
        color: #fff;
        text-decoration: underline;
    }
    a.btn-mini {
        text-decoration: none;
        background: $gray-lightest;
        color: $headings-color;
        &:hover {
            color: $headings-color;
        }
        @include media-breakpoint-down(lg) {
            margin-right: 3.1em;
        }
        @include media-breakpoint-down(md) {
            margin-top: 1em;
            margin-right: 0;
        }
    }
    &.accepted {
        display: none;
    }
}
