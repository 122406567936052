.material-icons {
    font-size: 1em;
    vertical-align: -10%;
}

.material-icons-2x {
    font-size: 2em;
    vertical-align: -25%;
}

.material-icons-fixed {
    width: 1.28571429em;
    text-align: center;
}

.material-icons-ul {
    padding-left: 0;
    margin-left: 0;
    list-style-type: none;
    > li {
        position: relative;
        padding-left: $spacer * 1.5;
        > .material-icons {
            font-size: 1.5em;
            position: absolute;
            left: 0;
            width: $spacer;
            text-align: left;
        }
    }
}
