@mixin e-canvas-padding {
    padding-right: $canvas-padding-xs;
    padding-left: $canvas-padding-xs;
    @include media-breakpoint-up(xl) {
        padding-right: $canvas-padding-xl;
        padding-left: $canvas-padding-xl;
    }
    @media (min-width: $canvas-max-width) {
        padding-right: $canvas-max-width / 10;
        padding-left: $canvas-max-width / 10;
    }
}

@mixin e-txt-dark {
    color: $section-light-color;
    h1,
    .h1,
    h2,
    .h2 {
        color: $section-light-headings-color;
    }
    h3,
    .h3,
    h4,
    .h4 {
        color: $section-light-color;
    }
    p a:not(.btn) {
        color: $section-light-link-color;
        //text-decoration: underline;
        &:hover {
            //text-decoration: none;
        }
    }
}

@mixin e-txt-light {
    color: $section-dark-color;
    h1,
    .h1,
    h2,
    .h2 {
        color: $section-dark-headings-color;
    }
    h3,
    .h3,
    h4,
    .h4 {
        color: $section-dark-color;
    }
    p a:not(.btn) {
        color: $section-dark-link-color;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}

@mixin e-bg-light {
    border-color: $section-light-border;
    background: $section-light-bg;
}

@mixin e-bg-dark {
    border-color: $section-dark-border;
    background: $section-dark-bg;
}

@mixin e-bg-light {
    border-color: $section-light-border;
    background: $section-light-bg;
}

@mixin e-bg-dark {
    border-color: $section-dark-border;
    background: $section-dark-bg;
}

@mixin e-section-primary {
    color: $section-primary-color;
    border-color: $section-primary-border;
    background: $section-primary-bg;
    h1,
    .h1,
    h2,
    .h2 {
        color: $section-primary-headings-color;
    }
    h3,
    .h3,
    h4,
    .h4 {
        color: $section-primary-color;
    }
    p a:not(.btn) {
        color: $section-primary-link-color;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .p .p-txt-container .table tbody {
        th,
        td {
            border-color: $section-primary-border;
        }
    }
}

@mixin e-section-secondary {
    color: $section-secondary-color;
    border-color: $section-secondary-border;
    background: $section-secondary-bg;
    h1,
    .h1,
    h2,
    .h2 {
        color: $section-secondary-headings-color;
    }
    h3,
    .h3 h4,
    .h4 {
        color: $section-secondary-color;
    }
    p a:not(.btn) {
        color: $section-secondary-link-color;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .p .p-txt-container .table tbody {
        th,
        td {
            border-color: $section-secondary-border;
        }
    }
}

@mixin e-section-variation-one {
    color: $section-variation-one-color;
    border-color: $section-variation-one-border;
    background: $section-variation-one-bg;
    h1,
    .h1,
    h2,
    .h2 {
        color: $section-variation-one-headings-color;
    }
    h3,
    .h3,
    h4,
    .h4 {
        color: $section-variation-one-color;
    }
    p a:not(.btn) {
        color: $section-variation-one-link-color;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .p .p-txt-container .table tbody {
        th,
        td {
            border-color: $section-variation-one-border;
        }
    }
}

@mixin e-section-variation-two {
    color: $section-variation-two-color;
    border-color: $section-variation-two-border;
    background: $section-variation-two-bg;
    h1,
    .h1,
    h2,
    .h2 {
        color: $section-variation-two-headings-color;
    }
    h3,
    .h3,
    h4,
    .h4 {
        color: $section-variation-two-color;
    }
    p a:not(.btn) {
        color: $section-variation-two-link-color;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
    .p .p-txt-container .table tbody {
        th,
        td {
            border-color: $section-variation-two-border;
        }
    }
}

@import "espresso/mixins/grid-framework";
@import "espresso/mixins/buttons";
