@mixin canvas-padding {
    padding-right: $canvas-padding-xs;
    padding-left: $canvas-padding-xs;
    @include media-breakpoint-up(xl) {
        padding-right: $canvas-padding-xl;
        padding-left: $canvas-padding-xl;
    }
    @media (min-width: $canvas-max-width) {
        padding-right: $canvas-max-width / 10;
        padding-left: $canvas-max-width / 10;
    }
}

/// Remove the unit of a length
/// @param {Number} $number - Number to remove unit from
/// @return {Number} - Unitless number
@function strip-unit($number) {
    @if type-of($number)=='number' and not unitless($number) {
        @return $number / ($number * 0 + 1);
    }
    @return $number;
}
