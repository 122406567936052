.p-divider {
    margin-bottom: $spacer;
    hr {
        margin: 0;
    }
}

.p-divider-thin hr {
    border-top-width: 1px;
}

.p-divider-thick hr {
    border-top-width: 0.375em;
}

.p-divider-double hr {}

.p-divider-transparent hr {
    border-color: rgba(0, 0, 0, 0);
}

.p-divider-white hr {
    border-color: #fff;
}

.p-divider-black hr {
    border-color: #000;
}

.p-divider-light hr {
    border-color: $section-light-bg;
}

.p-divider-dark hr {
    border-color: $section-dark-bg;
}

.p-divider-brand-primary hr {
    border-color: $brand-primary;
}

.p-divider-brand-secondary hr {
    border-color: $brand-secondary;
}

.p-divider-brand-variation-one hr {
    border-color: $brand-variation-one;
}

.p-divider-brand-variation-two hr {
    border-color: $brand-variation-two;
}
