// Kill the scroll on the body
.modal-open {
    //http://stackoverflow.com/questions/32675849/screen-zooms-in-when-a-bootstrap-modal-is-opened-on-ios-9-safari
    min-height: 100vh;
}

// Container that the modal scrolls within
.modal {
    &.fade .modal-dialog {
        transition: transform $slide-speed $slide-easing;
    }
}

// Actual modal
.modal-content {
    box-shadow: $shadow-lg;
}

// Modal sizes
.modal-fullscreen {
    .modal-open & {
        overflow-y: scroll;
    }
    .modal-dialog {
        width: 100%;
        margin: 0;
        transition: none;
    }
    .modal-content {
        min-height: 100vh;
        border: 0;
        border-radius: 0;
        box-shadow: none;
    }
}
