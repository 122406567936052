.e-footer {
    font-size: $small-font-size;
}

.e-footer-content {
    padding-top: $spacer * 1.5;
    padding-bottom: $spacer * 1.5;
    color: $footer-color;
    background: $footer-bg;
    h1,
    h2,
    h3 {
        color: $footer-headings-color;
    }
    h4,
    p,
    ul {
        color: $footer-color;
    }
    a {
        color: $footer-link-color;
    }
    svg {
        [id*="Filled_Icons"] {
            fill: $footer-color;
            opacity: 0.85;
        }
        [id*="Outline_Icons"] {
            path,
            rect,
            circle,
            ellipse,
            line,
            polyline,
            polygon {
                stroke: $footer-color;
                opacity: 0.85;
            }
        }
    }
    .form-control {
        background-color: $footer-headings-color;
    }
}

.e-footer-copyright {
    font-size: $small-font-size;
    padding-top: $spacer / 2;
    padding-bottom: $spacer / 2;
    color: $footer-copyright-color;
    background: $footer-copyright-bg;
    p {
        margin: 0;
        color: $footer-copyright-color;
    }
    a {
        color: $footer-copyright-link-color;
        &:hover {
            color: $footer-copyright-link-hover-color;
        }
    }
    .e-copyright {}
    .website-by-co3 {}
}
