@include media-breakpoint-down($nav-stacking-breakpoint) {
    //Overrides to fix collapse vs. dropdown conflicts.
    .e-nav-global {
        .collapse {
            display: none;
        }
        .collapse.in {
            display: block;
        }
        .dropdown-toggle::after {
            display: none;
        }
        .dropdown-menu {
            position: static;
            float: none;
            min-width: 0;
            border: 0;
            box-shadow: none;
            background: none;
            padding: 0;
            font-size: inherit;
        }
    }
    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-global {
        font-family: $font-family-monospace;
        margin-bottom: $spacer * 2;
        border-top: $border-width solid $header-border-color;
        border-bottom: $border-width solid $header-border-color;
        background: rgba($header-color, 0.1);
        // All levels.
        .collapsing {
            display: block;
        }
        .nav {
            margin: 0 0 ($spacer * 0.5) ($spacer * 1.25);
        }
        .nav-item {}
        .nav-link {
            display: block;
            padding: ($spacer * 0.5) ($spacer * 0.0625);
            color: rgba($header-color, 0.85);
            background: none;
            white-space: normal;
            &.is-active {
                font-weight: $font-weight-bold;
                color: $brand-primary;
            }
        }
        // First level only.
        > .nav {
            margin: 0 ( $grid-gutter-width / 2);
            padding: 0 $canvas-padding-xs;
            > .nav-item {
                opacity: 0;
                transform: translateX($spacer * -2);
                transition: all $slide-speed $slide-easing;
                $item-entry-delay: 0.05s;
                @for $item from 1 through 10 {
                    &:nth-child(#{$item}) {
                        transition-delay: $item-entry-delay * $item;
                    }
                }
                + .nav-item {
                    border-top: $border-width solid $header-border-color;
                }
                > .nav-link {
                    text-transform: uppercase;
                    font-weight: $font-weight-bold;
                }
            }
        }
        //Props for item animation entry in active modal.
        .in & {
            // First level only.
            > .nav {
                > .nav-item {
                    opacity: 1;
                    transform: translateX(0);
                }
            }
        }
        .dropdown-menu {
            .dropdown-item {
                background: none;
                .nav-link {}
            }
        }
        .e-megamenu-container {
            .close {
                display: none;
            }
        }
        .e-megamenu {
            .container-fluid {
                padding: 0;
            }
            .row {
                margin: 0;
                display: block;
            }
            .e-megamenu-content {
                display: none;
            }
        }
        .e-megamenu-item {
            .e-megamenu-header {}
            .nav {
                .nav-item {}
                .nav-link {}
            }
        }
    }
}

@include media-breakpoint-up(breakpoint-next($nav-stacking-breakpoint)) {
    //Overrides to fix collapse vs. dropdown conflicts.
    .e-nav-global {
        .collapse {
            display: none;
        }
        .dropdown.open > .collapse,
        .e-dropdown-mega.open .collapse {
            display: block;
        }
    }
    .dropdown-menu {
        transform: translateY(0);
        transition: transform $slide-speed $slide-easing;
        will-change: transform;
        .e-header.is-hidden & {
            transform: translateY(-110%);
        }
    }
    //Override inline styling after modal close, in case of resize after using menu below nav-stacking-breakpoint
    .e-nav-container.modal[style^="display"] {
        display: flex !important;
    }
    //Container styling. All sizes and flexbox props resides in _e-header.scss
    .e-nav-global {
        font-family: $font-family-monospace;
        display: flex;
        > .nav {
            display: flex;
            justify-content: center;
            align-items: stretch;
            >.nav-item {
                display: flex;
                align-items: stretch;
                padding: 0 ($spacer * 0.0625);
                >.nav-link {
                    display: flex;
                    align-items: center;
                    padding: ($spacer * 1) ($spacer * 0.875);
                    color: $header-color;
                    font-size: 0.75em;
                    line-height: 1.25;
                    font-weight: $font-weight-bold;
                    letter-spacing: 0.025em;
                    text-transform: uppercase;
                    position: relative;
                    &.is-inpath,
                    &.is-active {
                        color: rgba($brand-primary, 1);
                    }
                    &:before {
                        content: "";
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        height: 0.25em;
                    }
                    &:hover {
                        &::before {
                            background: rgba($header-color, 1);
                        }
                    }
                    &.is-inpath,
                    &.is-active {
                        &::before {
                            background: rgba($brand-primary, 1);
                        }
                    }
                    .material-icons {
                        font-size: 2em;
                        margin-right: 0.25em;
                    }
                }
                >.nav {
                    display: none;
                }
            }
        }
        .dropdown {
            .dropdown-toggle {
                //Menu hover/active element
                &::before {
                    display: block;
                }
                //Dropdown active arrow
                &::after {
                    display: none;
                    content: "";
                    position: absolute;
                    bottom: -1px;
                    left: 50%;
                    z-index: $zindex-dropdown + 1;
                    transform: translate(-50%, 0);
                    border-top: 0;
                    border-right: $caret-width * 2 solid rgba(0, 0, 0, 0);
                    border-bottom: $caret-width * 2 solid $dropdown-bg;
                    border-left: $caret-width * 2 solid rgba(0, 0, 0, 0);
                    margin: 0;
                }
            }
            &.open {
                .dropdown-toggle {
                    &::before {
                        //display: none;
                    }
                    &::after {
                        display: block;
                    }
                }
            }
        }
        .dropdown-menu {
            font-family: $font-family-base;
            color: $gray-darkest;
            font-size: $small-font-size;
            border: 0;
            border-radius: 0 0 $border-radius $border-radius;
            left: -1.25em;
            .dropdown-item {
                padding: 0;
                .nav-link {
                    padding: 0.5em 2.5em;
                    display: block;
                    color: inherit;
                }
            }
        }
        .e-dropdown-mega {
            position: static;
        }
        .e-megamenu-container {
            left: 0;
            right: 0;
            max-height: 100vh;
            overflow: auto;
            padding: 0;
        }
        .e-megamenu {
            @include e-canvas-padding;
            max-width: $canvas-max-width;
            margin: 0 auto;
            padding: ($spacer * 1.5) ($grid-gutter-width / 2) ($spacer * 2);
            .e-megamenu-content {
                @include make-col();
                @include make-col-span(12);
                @include media-breakpoint-up(md) {
                    @include make-col-span(10);
                    @include make-col-offset(1)
                }
                @include media-breakpoint-up(lg) {
                    @include make-col-span(8);
                    @include make-col-offset(2)
                }
                font-size: $small-font-size;
                padding-bottom: $spacer * 1.5;
                h1,
                h2,
                h3,
                h4,
                p {
                    margin-top: 0;
                    margin-bottom: 0.5rem;
                }
                a {
                    color: inherit;
                    text-decoration: underline;
                    &:hover {
                        text-decoration: none;
                    }
                }
            }
            .e-megamenu-nav {
                @include make-col();
                @include make-col-span(12);
                > .nav {
                    @include make-row();
                }
            }
            .e-megamenu-item {
                @include make-col();
                @include make-col-span(3);
                .e-megamenu-header {
                    //font-family: $font-family-monospace;
                    font-size: $font-size-h3;
                    font-weight: $font-weight-h3;
                    color: $section-light-color;
                    margin-top: $headings-margin-top;
                    margin-bottom: $headings-margin-bottom / 2;
                    //text-transform: uppercase;
                    //opacity: .65;
                    pointer-events: none;
                }
                .nav {
                    .nav-item {
                        line-height: 1.375;
                    }
                    .nav-link {
                        display: block;
                        color: $section-light-color;
                        padding-left: 1.5em;
                        padding-top: 0.25em;
                        padding-bottom: 0.25em;
                        position: relative;
                        &::before {
                            content: '–';
                            color: $section-light-color;
                            opacity: $text-muted;
                            position: absolute;
                            left: 0;
                        }
                        &::after {
                            color: $link-color;
                            content: 'arrow_forward';
                            font-family: 'Material Icons';
                            font-weight: normal;
                            font-style: normal;
                            font-size: 1em;
                            vertical-align: -10%;
                            letter-spacing: normal;
                            text-transform: none;
                            display: inline-block;
                            white-space: nowrap;
                            word-wrap: normal;
                            direction: ltr;
                            font-feature-settings: 'liga';
                            font-smoothing: antialiased;
                            width: 0;
                            opacity: 0;
                            transform: translateX(-1em);
                        }
                        &:hover {
                            color: $link-color;
                            &::after {
                                opacity: 1;
                                transform: translateX(0.5em);
                                transition: opacity $fade-speed / 2 $fade-easing, transform $slide-speed / 2 $slide-easing;
                            }
                        }
                    }
                }
            }
            &.e-megamenu-item-count-2 {
                .e-megamenu-item {
                    @include make-col-span(5);
                }
            }
            &.e-megamenu-item-count-3,
            &.e-megamenu-item-count-6 {
                .e-megamenu-item {
                    @include make-col-span(4);
                }
            }
            &.e-megamenu-item-count-5 {
                .e-megamenu-item {
                    @include make-col-span(2.4);
                }
            }
            &.e-megamenu-item-count-7,
            &.e-megamenu-item-count-8 {
                .e-megamenu-item {
                    @include make-col-span(2);
                }
            }
        }
    }
}
