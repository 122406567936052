.fade {
    transition-timing-function: $fade-easing;
    transition-duration: $fade-speed;
}

.collapsing {
    transition-timing-function: $collapse-easing;
    transition-duration: $collapse-speed;
}

@keyframes rotate {
    0 {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}