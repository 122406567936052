.dropdown-toggle {
    // Generate the caret automatically
    &::after {
        margin-right: 0.5em;
        margin-left: 0.5em;
    }
}

// The dropdown menu
.dropdown-menu {
    font-size: $small-font-size;
    min-width: 10 * $spacer;
    padding: 0.75em 0;
    margin: 0; // override default ul
    color: inherit;
    box-shadow: $shadow-lg;
}

// Links, buttons, and more within the dropdown menu
//
// `<button>`-specific styles are denoted with `// For <button>s`
.dropdown-item {
    padding: 0.5em 1.25em;
    // Active state
    &.is-active {
        color: $dropdown-link-active-color;
        background-color: $dropdown-link-active-bg;
    }
}
