.e-product-container {
    font-size: 0.75em;
    position: relative;
    z-index: 2;
    padding: $spacer;
}

.e-product-text-container {
    font-size: $small-font-size;
    margin-bottom: $spacer;
}

.e-product-name {}

.e-product-text {}

.e-product-price {
    font-size: 1.25em;
}

.e-product-price-before {
    text-decoration: line-through;
}

.e-product-form {
    position: relative;
    .e-loading-overlay {
        background: transparent; //$brand-primary;
    }
    .e-loading-spinner {
        left: 10%;
        background: currentColor;
        background: linear-gradient(60deg, currentColor 20%, rgba(255, 255, 255, 0) 60%);
    }
    .e-loading-spinner::before {
        background: currentColor;
    }
    .e-loading-spinner::after {
        background: $btn-primary-bg-hover;
    }
}

.e-product-image-container {
    position: relative;
    padding-top: percentage( 80 / 100);
    margin-bottom: $spacer;
    .e-product-image-wrapper {}
    .carousel {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        height: auto;
        @include media-breakpoint-down(sm) {
            margin-bottom: $spacer;
        }
    }
    .carousel-item {
        .e-product-image {
            width: auto;
            height: auto;
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            cursor: zoom-in;
        }
    }
    .carousel-indicators {
        @include media-breakpoint-down(sm) {
            bottom: $spacer * -1;
            height: $spacer;
            justify-content: flex-start;
            li {
                //border-color: rgba(0, 0, 0, 0);
                background: $body-color;
                opacity: $text-muted;
                &.active {
                    border-color: $link-color;
                    background: rgba(0, 0, 0, 0);
                    opacity: 1;
                }
            }
        }
        @include media-breakpoint-up(md) {
            font-size: 1.5em;
            position: static;
            margin: 0 0.5em 0 0;
            width: 2.5em;
            display: flex;
            flex-flow: column;
            justify-content: flex-start;
            order: -1;
            li {
                font-size: 1em;
                position: relative;
                margin: 0 0 0.5em 0;
                //border-color: $border-color;
                border-radius: 0;
                background-color: rgba(0, 0, 0, 0);
                &.active {
                    border-color: $link-color;
                }
                span {
                    position: absolute;
                    top: 0.25em;
                    right: 0.25em;
                    bottom: 0.25em;
                    left: 0.25em;
                    background-color: rgba(0, 0, 0, 0);
                    background-size: contain;
                    background-position: 50% 50%;
                    background-repeat: no-repeat;
                }
            }
        }
    }
    .e-product-image-modal-link {
        @include media-breakpoint-up(md) {
            display: none;
        }
        display: flex;
        position: absolute;
        right: 0;
        bottom: $spacer * -1;
        z-index: 16;
        .material-icons {
            font-size: 1.375em;
            vertical-align: -26%;
            padding-right: 0.25em;
            color: $body-color;
        }
    }
}

.e-product-image-modal {
    &.modal-fullscreen {
        overflow-y: hidden;
        .modal-content {
            min-height: 0;
        }
    }
    .modal-dialog,
    .modal-content,
    .modal-body,
    .carousel {
        height: 100%;
    }
    .modal-body {
        padding: 0;
    }
    .carousel-item {
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
    }
    .carousel-control {
        &.left {
            left: 0.5em;
        }
        &.right {
            right: 0.5em;
        }
    }
    .carousel-indicators {
        li {
            //border-color: $border-color;
            background-color: $body-color;
            opacity: $text-muted;
            @include media-breakpoint-up(md) {
                background: #fff;
                opacity: 1;
                font-size: 1.5em;
                margin: $spacer / 6;
                border-radius: 0;
            }
            &.active {
                border-color: $link-color;
                background: #fff;
                opacity: 1;
            }
            span {
                display: none;
                @include media-breakpoint-up(md) {
                    display: block;
                }
                position: absolute;
                top: 0.25em;
                right: 0.25em;
                bottom: 0.25em;
                left: 0.25em;
                background-size: contain;
                background-position: 50% 50%;
                background-repeat: no-repeat;
            }
        }
    }
}

.e-product-section {
    padding: $spacer 0;
    border-top: $border-width solid $border-color;
}

.e-product-section-header {
    padding-bottom: $spacer;
    &[data-toggle="collapse"] {
        cursor: pointer;
    }
}
