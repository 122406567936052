.p-banner {
    @include e-canvas-padding;
    position: relative;
    margin-bottom: $spacer;
    display: flex;
    @for $size from 1 through 5 {
        &.p-t-#{$size} {
            padding-top: ( $spacer * $size) !important;
        }
        &.p-b-#{$size} {
            padding-bottom: ( $spacer * $size) !important;
        }
    }
    .p-txt-container {
        width: 100%;
    }
    .p-txt-wrapper {
        padding-top: $spacer / 2;
        padding-bottom: $spacer / 2;
        @include media-breakpoint-up(md) {
            padding-top: $spacer;
            padding-bottom: $spacer;
        }
        @include media-breakpoint-up(lg) {
            padding-top: $spacer * 1.5;
            padding-bottom: $spacer * 1.5;
        }
    }
    h1,
    h2,
    h3,
    h4,
    p {
        color: $section-dark-headings-color;
        margin-bottom: 0.5rem;
        a:not(.btn) {
            color: inherit;
        }
        &:last-child {
            margin-bottom: 0;
        }
    }
    p {
        color: $section-dark-color;
        a:not(.btn) {
            text-decoration: underline;
        }
    }
    .p-img-container {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-size: cover;
        background-position: 50% 50%;
        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            @include media-breakpoint-down(sm) {
                background: rgba(0, 0, 0, 0.45) !important;
            }
        }
    }
}

.p-banner-auto {
    .p-txt-wrapper {
        padding-top: $spacer * 2;
        padding-bottom: $spacer * 2;
    }
}

.p-banner-small {
    @extend .p-section-small;
}

.p-banner-medium {
    @extend .p-section-medium;
}

.p-banner-large {
    @extend .p-section-large;
}

.p-banner-fullscreen {
    @extend .p-section-fullscreen;
}

.p-banner-left-top {
    align-items: flex-start;
    .p-txt-wrapper {
        justify-content: flex-start;
        padding-bottom: $spacer * 2;
    }
    .p-img-container::after {
        background: linear-gradient(157deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 80%);
    }
}

.p-banner-left-middle {
    align-items: center;
    .p-txt-wrapper {
        justify-content: flex-start;
        padding-top: $spacer * 2;
        padding-bottom: $spacer * 2;
    }
    .p-img-container::after {
        background: linear-gradient(90deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 80%);
    }
}

.p-banner-left-bottom {
    align-items: flex-end;
    .p-txt-wrapper {
        justify-content: flex-start;
        padding-top: $spacer * 2;
    }
    .p-img-container::after {
        background: linear-gradient(22deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 80%);
    }
}

.p-banner-center-top {
    align-items: flex-start;
    .p-txt-wrapper {
        justify-content: center;
        padding-bottom: $spacer * 2;
    }
    .p-img-container::after {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 65%);
    }
}

.p-banner-center-middle {
    align-items: center;
    .p-txt-wrapper {
        justify-content: center;
        padding-top: $spacer * 2;
        padding-bottom: $spacer * 2;
    }
    .p-img-container::after {
        background: rgba(0, 0, 0, 0.55);
    }
}

.p-banner-center-bottom {
    align-items: flex-end;
    .p-txt-wrapper {
        justify-content: center;
        padding-top: $spacer * 2;
    }
    .p-img-container::after {
        background: linear-gradient(0deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 65%);
    }
}

.p-banner-right-top {
    align-items: flex-start;
    .p-txt-wrapper {
        justify-content: flex-end;
        padding-bottom: $spacer * 2;
    }
    .p-img-container::after {
        background: linear-gradient(202deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 80%);
    }
}

.p-banner-right-middle {
    align-items: center;
    .p-txt-wrapper {
        justify-content: flex-end;
        padding-top: $spacer * 2;
        padding-bottom: $spacer * 2;
    }
    .p-img-container::after {
        background: linear-gradient(270deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 80%);
    }
}

.p-banner-right-bottom {
    align-items: flex-end;
    .p-txt-wrapper {
        justify-content: flex-end;
        padding-top: $spacer * 2;
    }
    .p-img-container::after {
        background: linear-gradient(338deg, rgba(0, 0, 0, 0.45) 0%, rgba(0, 0, 0, 0) 80%);
    }
}

.p-banner {
    &.p-banner-gradient-disabled {
        .p-img-container::after {
            background: none;
        }
    }
}

//TODO: Omdøbes til .p-banner-img
.p-banner-bg-left-top {
    .p-img-container {
        background-position: 0 0;
    }
}

.p-banner-bg-left-middle {
    .p-img-container {
        background-position: 0 50%;
    }
}

.p-banner-bg-left-bottom {
    .p-img-container {
        background-position: 0 100%;
    }
}

.p-banner-bg-center-top {
    .p-img-container {
        background-position: 50% 0;
    }
}

.p-banner-bg-center-middle {
    .p-img-container {
        background-position: 50% 50%;
    }
}

.p-banner-bg-center-bottom {
    .p-img-container {
        background-position: 50% 100%;
    }
}

.p-banner-bg-right-top {
    .p-img-container {
        background-position: 100% 0;
    }
}

.p-banner-bg-right-middle {
    .p-img-container {
        background-position: 100% 50%;
    }
}

.p-banner-bg-right-bottom {
    .p-img-container {
        background-position: 100% 100%;
    }
}
