@mixin material-icons($icon) {
    //God ide med mixin. Overvej dig lige om det kan laves med markup og show/hide klasser. Dette giver rigitg meget ekstra css.
    content: $icon;
    font-family: "Material Icons";
    font-style: normal;
    font-size: 1em;
    vertical-align: -10%;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    direction: ltr;
    font-feature-settings: "liga";
    font-smoothing: antialiased;
    color: inherit;
    transition: transform $slide-speed / 2 $slide-easing;
    text-decoration: none;
}

.e-checkout-page {
    .e-checkout-loading-overlay {
        position: fixed;
    }
    .e-checkout {
        min-height: 100vh;
        .e-checkout-step-loading-overlay {
            background: rgba($loading-bg, .85);
        }
    }
    .e-checkout-step {
        box-shadow: none;
        border: $border-width solid $border-color;
        border-radius: $border-radius;
        margin-top: $border-width * -1;
        margin-bottom: $spacer;
        padding: $spacer;
        &.e-checkout-step-active {
            .e-checkout-step-header {
                color: inherit;
            }
            .e-checkout-step-header-badges-index {
                background: $brand-primary;
                display: inline-block;
            }
        }
        &.e-checkout-step-active {
            .e-checkout-step-header {
                color: inherit;
            }
            .e-checkout-step-header-badges-index {
                background: $brand-primary;
                display: inline-block;
            }
        }
        &.e-checkout-step-completed {
            .e-checkout-step-header-badges-completed {
                background: $brand-success;
                display: inline-block;
            }
            .e-checkout-step-summary {
                max-height: 100vh;
            }
            @include media-breakpoint-down(sm) {
                display: none;
            }
        }
        &.e-checkout-step-has-changed {
            .e-checkout-step-header-badges-warning {
                background: $brand-danger;
                display: inline-block;
            }
        }
        .e-checkout-step-card-block {
            padding: ($card-spacer-x / 2);
            @include media-breakpoint-down(md) {
                padding: 0!important;
            }
        }
        .e-checkout-next-step {
            @include media-breakpoint-down(md) {
                width: auto;
                float: right;
            }
        }
        .e-checkout-toggle-target {
            &.is-hidden {
                display: none;
            }
        }
        .e-checkout-step-header-badges {
            span {
                display: inline-block;
                color: white;
                background: $gray;
                border-radius: 100%;
                width: 35px;
                height: 35px;
                text-align: center;
                margin-right: .5em;
                &.e-checkout-step-header-badges-warning {
                    display: none;
                }
            }
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        .e-checkout-step-header {
            font-size: $font-size-lg;
            transition: all .25s $collapse-easing;
            background: transparent;
            color: $gray;
            padding: ($card-spacer-x / 2);
        }
        .e-checkout-step-list-group {
            .list-group-item {
                display: flex;
                justify-content: space-between;
            }
        }
        .e-checkout-step-summary {
            font-size: $font-size-xs;
            max-height: 0;
            transition: max-height $collapse-speed $collapse-easing;
            overflow: hidden;
            color: $gray-light;
            .e-checkout-step-summary-btn-edit {
                margin-left: .5em;
            }
        }
        @include media-breakpoint-down(md) {
            box-shadow: none;
            display: none;
            padding: 0;
            border:0;
            &.e-checkout-step-active {
                display: block;
                .e-checkout-step-header {
                    background: transparent;
                    border: 0;
                    padding: 0;
                }
            }
        }
    }
    .e-checkout-progress {
        ul {
            display: flex;
            justify-content: space-between;
            list-style: none;
            li {
                display: flex;
                flex-direction: column;
                position: relative;
                justify-content: center;
                align-items: center;
                font-size: $font-size-sm;
                color: $gray-light;
                &::before {
                    display: flex;
                    justify-content: center;
                    content: attr(data-index);
                    background: $gray-lightest;
                    border-radius: 50%;
                    width: 2rem;
                    height: 2rem;
                    padding: .2rem 0;
                    text-align: center;
                    border: 2px solid $gray-lightest;
                }
                &.e-checkout-progress-completed {
                    color: $brand-success;
                    cursor: pointer;
                    &::before {
                        @include material-icons('done');
                        background: $brand-success;
                        color: white;
                        border-color: $brand-success;
                    }
                }
                &.e-checkout-progress-active {
                    color: $gray-darkest;
                    &::before {
                        //::
                        font-weight: $font-weight-bold;
                        background: $gray-lightest;
                        color: $gray-darkest;
                        border-color: $brand-success;
                    }
                }
            }
        }
    }
    .e-checkout-form {
        .checkbox-toggle {
            float: right;
            padding: .5em;
            font-size: .7em;
            margin: .3em 0 -3em;
            input {
                margin-top: .35em;
            }
        }
    }
    .e-checkout-shippingmethod-gls {
        .e-checkout-shippingmethod-gls-results {
            margin: $card-spacer-y 0;
            display: none;
            .card {
                margin-bottom: $card-spacer-y;
            }
        }
    }
    .e-checkout-cart {
        background: $content-bg;
        position: sticky;
        top: 5em;
    }
    .collapsing {
        transition-duration: $collapse-speed;
    }
    @include media-breakpoint-down(md) {
        .collapsing {
            transition: none;
        }
    }
    .e-checkout-modal {
        font-size: $small-font-size;
        .card {
            box-shadow: none;
            .e-checkout-cart-list {
                list-style: none;
                padding: 0;
                font-size: $small-font-size;
                li {
                    border-bottom: 1px solid $gray-lightest;
                    padding: 2% 5%;
                    margin: 0;
                    &.e-checkout-cart-list-item {
                        background: white;
                        &.e-checkout-cart-orderline {
                            background: $content-bg;
                            .e-checkout-cart-orderline-label {}
                        }
                        .e-checkout-cart-content {
                            display: flex;
                            justify-content: space-between;
                        }
                        &:last-child {
                            border-bottom-width: 0;
                            font-weight: $font-weight-bold;
                        }
                    }
                }
            }
            .card-header,
            .card-block,
            .card-footer {
                background: transparent;
                padding: 0;
            }
        }
    }
    .e-checkout-utillity-bar {
        display: flex;
        justify-content: space-between;
        margin-bottom: $btn-toolbar-margin;
        &.e-checkout-utillity-align-items {
            align-items: center;
        }
        .e-checkout-next-step{
            margin-left:auto;
        }
    }
    &.e-checkout-page-approve {
        .e-checkout .e-checkout-step .e-checkout-step-summary {
            color: $gray-darkest;
        }
        .e-checkout-step .e-checkout-step-header{
            color: inherit;
        }
        .e-checkout-step.e-checkout-step-completed .e-checkout-step-header-badges-completed{
            display: none;
        }
    }
}
