// scss-lint:disable QualifyingElement
//
// Base styles
//
.btn {
    @include button-size($btn-padding-y, $btn-padding-x, $btn-font-size, $btn-border-radius);
    font-family: $btn-font-family;
    text-transform: uppercase;
    &:hover {
        transition: none;
    }
    //form & { font-size: 1em; }
}

p > .btn:nth-last-child(n+2):first-child,
p > .btn:nth-last-child(n+2):first-child ~ .btn {
    @include media-breakpoint-down(xs) {
        display: block;
        margin-right: 0;
    }
    margin-right: $spacer-x;
    margin-bottom: $btn-block-spacing-y;
}

//
// Link buttons
//
// Make a button look and behave like a link
.btn-link {
    @include box-shadow(none);
    font-weight: $btn-font-weight;
}

//
// Button Sizes
//
.btn-sm {
    font-size: 0.675em
}
.btn-lg {
    font-size: 1em;
}


//
// Alternate buttons
//
.btn-primary {
    @include button-variant($btn-primary-color, $btn-primary-bg, $btn-primary-border, $btn-primary-bg-hover);
}

.btn-secondary {
    @include button-variant($btn-secondary-color, $btn-secondary-bg, $btn-secondary-border, $btn-secondary-bg-hover);
    //border-width: $border-width;
}
