//
// Espresso extension to preview contant in collapsed containers
//
.collapse-peek {}

.collapse-peek-disabled {
    .collapse-peek-toggle {
        display: none;
    }
}

.collapse-peek-content {
    position: relative;
    &:not(.in) {
        display: block;
        overflow: hidden;
        //Size - Medium (Default)
        height: $collapse-peek-height-md;
        min-height: $collapse-peek-height-md;
        @include media-breakpoint-down(xs) {
            height: $collapse-peek-height-md / 1.5;
            min-height: $collapse-peek-height-md / 1.5;
        }
        //Size - Small
        .collapse-peek-sm & {
            height: $collapse-peek-height-sm;
            min-height: $collapse-peek-height-sm;
            @include media-breakpoint-down(xs) {
                height: $collapse-peek-height-sm / 1.5;
                min-height: $collapse-peek-height-sm / 1.5;
            }
        }
        //Size - Large
        .collapse-peek-lg & {
            height: $collapse-peek-height-lg;
            min-height: $collapse-peek-height-lg;
            @include media-breakpoint-down(xs) {
                height: $collapse-peek-height-lg / 1.5;
                min-height: $collapse-peek-height-lg / 1.5;
            }
        }
    }
    //Overlay
    &::after,
    &.is-collapsing::after {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: linear-gradient(0deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 2.5%, rgba(255, 255, 255, 0) 85%);
        opacity: 1;
    }
    &.in::after {
        top: 100%;
        opacity: 0;
    }
    &.is-collapsing::after {
        top: 0;
        opacity: 1;
        transition: all $collapse-speed / 1.5 $collapse-easing;
    }
    &.is-expanding::after {
        top: 100%;
        opacity: 0;
        transition: all $collapse-speed / 1.5 $collapse-easing;
    }
    & {
        //Toggle buttons
        + .collapse-peek-toggle .collapse-peek-btn-show {
            display: inline;
        }
        + .collapse-peek-toggle .collapse-peek-btn-hide {
            display: none;
        }
    }
    &.in {
        //Toggle buttons
        + .collapse-peek-toggle .collapse-peek-btn-show {
            display: none;
        }
        + .collapse-peek-toggle .collapse-peek-btn-hide {
            display: inline;
        }
    }
}
